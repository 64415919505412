var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-hidden"},[_c('b-row',{staticClass:"d-flex justify-content-center mt-sm-5"},[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-card',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('p',[_vm._v(_vm._s(_vm.$t('demo-title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('advantages-title'))+":")]),_c('div',{staticClass:"my-3"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('advantages-title1'))+":")]),_c('span',{staticClass:"text-justify"},[_vm._v(" "+_vm._s(_vm.$t('advantages-desc1'))+" ")])])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('advantages-title2'))+":")]),_c('span',{staticClass:"text-justify "},[_vm._v(" "+_vm._s(_vm.$t('advantages-desc2'))+" ")])])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"font-weight-bolder text-nowrap"},[_vm._v(_vm._s(_vm.$t('advantages-title3'))+":")]),_c('span',{staticClass:"text-justify "},[_vm._v(" "+_vm._s(_vm.$t('advantages-desc3'))+" ")])])]),_c('div',{staticClass:"d-flex"},[_c('div',[_c('span',{staticClass:"font-weight-bolder text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('advantages-title4'))+":")]),_c('span',{staticClass:"text-justify "},[_vm._v(" "+_vm._s(_vm.$t('advantages-desc4'))+" ")])])])]),_c('p',[_vm._v(_vm._s(_vm.$t('demo-title2')))]),_c('b-form',{ref:"createDemoAccount",staticClass:"mt-4"},[_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('firstName_lastName'),"label-for":"firstName_lastName"}},[_c('validation-provider',{attrs:{"name":_vm.$t('firstName_lastName'),"vid":"first_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","name":_vm.$t('firstName_lastName'),"placeholder":_vm.$t('firstName_lastName')},model:{value:(_vm.full_name),callback:function ($$v) {_vm.full_name=$$v},expression:"full_name"}}),_c('small',{staticClass:"text-danger position-absolute"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":_vm.$t('email'),"vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":_vm.$t('email'),"placeholder":"john@example.com"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger position-absolute"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label-for":"mobile-country-code","label":_vm.$t('mobile_country_code')}},[_c('validation-provider',{ref:"mobile_country_code",attrs:{"name":_vm.$t('mobile_country_code'),"vid":"mobile_country_code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"mobile-country-code","options":_vm.formsListData.pre_country_code,"label":"precode","placeholder":_vm.$t('mobile_country_code'),"autocomplete":"nope","dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"w-100 d-flex ",attrs:{"dir":"ltr"}},[_c('img',{staticClass:"language-image",attrs:{"src":option.image}}),_vm._v("   "),_c('span',{staticClass:"select d-block"},[_vm._v("+"+_vm._s(option.precode))]),_vm._v("   "),_c('span',{staticClass:" d-block"},[_vm._v(" ("+_vm._s(option.name)+")")])])]}}],null,true),model:{value:(_vm.country_id),callback:function ($$v) {_vm.country_id=$$v},expression:"country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label-for":"mobile_number","label":_vm.$t('mobile_number_without_zero')}},[_c('validation-provider',{ref:"mobile",attrs:{"name":_vm.$t('mobile_number'),"vid":"mobile","rules":"required|noZeroStater"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-md-flex "},[_c('b-form-input',{attrs:{"id":"mobile","name":_vm.$t('mobile_number'),"placeholder":_vm.$t('mobile_number')},on:{"keypress":function($event){return _vm.AlphaNumericAndEspecialCharacters($event)}},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}})],1),_c('small',{staticClass:"text-danger position-absolute"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label-for":"platform","label":_vm.$t('forex.platform')}},[_c('validation-provider',{attrs:{"name":_vm.$t('forex.platform'),"vid":"platform","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"platform","clearable":false,"options":_vm.platforms,"label":"text","placeholder":_vm.$t('select-platform'),"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'},on:{"input":_vm.onchangePlatform},model:{value:(_vm.platformSelected),callback:function ($$v) {_vm.platformSelected=$$v},expression:"platformSelected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label-for":"group","label":_vm.$t('forex.group')}},[_c('validation-provider',{ref:"group",attrs:{"name":_vm.$t('forex.group'),"vid":"group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"group","placeholder":_vm.$t('forex.group'),"options":_vm.group,"clearable":true,"label":"name","dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'},on:{"input":_vm.onchangeGroup},model:{value:(_vm.groupSelected),callback:function ($$v) {_vm.groupSelected=$$v},expression:"groupSelected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-1",attrs:{"label-for":"leverage","label":_vm.$t('forex.leverage')}},[_c('validation-provider',{attrs:{"name":_vm.$t('forex.leverage'),"vid":"leverage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"leverage","label":"name","options":_vm.leverage},model:{value:(_vm.leverageSelected),callback:function ($$v) {_vm.leverageSelected=$$v},expression:"leverageSelected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{staticClass:"mt-1"},[_c('vue-recaptcha',{ref:"recaptcha",attrs:{"sitekey":_vm.sitekey,"load-recaptcha-script":true,"theme":_vm.$store.state.appConfig.layout.skin == 'dark' ? 'dark' : ''},on:{"expired":_vm.onCaptchaExpired,"verify":_vm.onVerify}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mt-2",attrs:{"id":"button","block":"","variant":"primary","disabled":invalid || !_vm.recaptcha},on:{"click":_vm.createAcc}},[(!_vm.pending)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('forex.create-account')))]):_vm._e(),(_vm.pending)?_c('b-spinner',{attrs:{"variant":"light","type":"grow","small":""}}):_vm._e()],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }