<template>
  <div class="overflow-hidden">
    <b-row class="d-flex justify-content-center mt-sm-5">
      <b-col
        cols="12"
        sm="6"
      >
        <b-card>
          <validation-observer v-slot="{ invalid }">
            <p>{{ $t('demo-title') }}</p>
            <p>{{ $t('advantages-title') }}:</p>
            <div class="my-3">
              <div class="d-flex">
                <div class="mb-1">
                  <span class="font-weight-bolder">  {{ $t('advantages-title1') }}:</span>
                  <span class="text-justify"> {{ $t('advantages-desc1') }}
                  </span>
                </div>
              </div>
              <div class="d-flex">
                <div class="mb-1">
                  <span class="font-weight-bolder">{{ $t('advantages-title2') }}:</span>
                  <span class="text-justify "> {{ $t('advantages-desc2') }}
                  </span>
                </div>
              </div>
              <div class="d-flex">
                <div class="mb-1">
                  <span class="font-weight-bolder text-nowrap">{{ $t('advantages-title3') }}:</span>
                  <span class="text-justify "> {{ $t('advantages-desc3') }}
                  </span>
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <span class="font-weight-bolder text-nowrap"> {{ $t('advantages-title4') }}:</span>
                  <span class="text-justify "> {{ $t('advantages-desc4') }}
                  </span>
                </div>
              </div>
            </div>
            <p>{{ $t('demo-title2') }}</p>
            <b-form
              ref="createDemoAccount"
              class="mt-4"
            >
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group
                  :label="$t('firstName_lastName')"
                  label-for="firstName_lastName"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('firstName_lastName')"
                    vid="first_name"
                    rules="required"
                  >
                    <b-form-input
                      id="first_name"
                      v-model="full_name"
                      :name="$t('firstName_lastName')"
                      :placeholder="$t('firstName_lastName')"
                    />
                    <small class="text-danger position-absolute">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group
                  :label="$t('email')"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('email')"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                      :name="$t('email')"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger position-absolute">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group
                  label-for="mobile-country-code"
                  :label="$t('mobile_country_code')"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="mobile_country_code"
                    :name="$t('mobile_country_code')"
                    vid="mobile_country_code"
                    rules="required"
                  >
                    <v-select
                      id="mobile-country-code"
                      v-model="country_id"
                      :options="formsListData.pre_country_code"
                      label="precode"
                      :placeholder="$t('mobile_country_code')"
                      autocomplete="nope"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    >
                      <template
                        slot="option"
                        slot-scope="option"
                        class="w-100 text-left"
                      >
                        <div
                          dir="ltr"
                          class="w-100 d-flex "
                        >
                          <img
                            :src="option.image"
                            class="language-image"
                          >
                          &nbsp;
                          <span class="select d-block">+{{ option.precode }}</span>
                          &nbsp;
                          <span class=" d-block"> ({{ option.name }})</span>
                        </div>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group
                  label-for="mobile_number"
                  :label="$t('mobile_number_without_zero')"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="mobile"
                    :name="$t('mobile_number')"
                    vid="mobile"
                    rules="required|noZeroStater"
                  >
                    <div class="d-md-flex ">
                      <b-form-input
                        id="mobile"
                        v-model="mobile"
                        :name="$t('mobile_number')"
                        :placeholder="$t('mobile_number')"
                        @keypress="AlphaNumericAndEspecialCharacters($event)"
                      />
                    </div>
                    <small class="text-danger position-absolute">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group
                  label-for="platform"
                  :label="$t('forex.platform')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('forex.platform')"
                    vid="platform"
                    rules="required"
                  >
                    <v-select
                      id="platform"
                      v-model="platformSelected"
                      :clearable="false"
                      :options="platforms"
                      label="text"
                      :placeholder="$t('select-platform')"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      @input="onchangePlatform"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group
                  label-for="group"
                  :label="$t('forex.group')"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="group"
                    :name="$t('forex.group')"
                    vid="group"
                    rules="required"
                  >
                    <v-select
                      id="group"
                      v-model="groupSelected"
                      :placeholder="$t('forex.group')"
                      :options="group"
                      :clearable="true"
                      label="name"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      @input="onchangeGroup"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label-for="leverage"
                  :label="$t('forex.leverage')"
                  class="mb-1"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('forex.leverage')"
                    vid="leverage"
                    rules="required"
                  >
                    <v-select
                      id="leverage"
                      v-model="leverageSelected"
                      label="name"
                      :options="leverage"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mt-1">
                  <vue-recaptcha
                    ref="recaptcha"
                    :sitekey="sitekey"
                    :load-recaptcha-script="true"
                    :theme="$store.state.appConfig.layout.skin == 'dark' ? 'dark' : ''"
                    @expired="onCaptchaExpired"
                    @verify="onVerify"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class=""
              >
                <b-button
                  id="button"
                  block
                  variant="primary"
                  class="mt-2"
                  :disabled="invalid || !recaptcha"
                  @click="createAcc"
                >
                  <span v-if="!pending">
                    {{ $t('forex.create-account') }}</span>
                  <b-spinner
                    v-if="pending"
                    variant="light"
                    type="grow"
                    small
                  />
                </b-button>
              </b-col>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  BSpinner,
  BForm,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueRecaptcha from 'vue-recaptcha'
import { required } from '@validations'
import MetatraderApis from '@/modules/forex/service/apis'
import FormApis from '@/services/apis/form'
import { alphanumeric } from '@/mixins/alphanumeric'

const demo = new MetatraderApis()
const formData = new FormApis()
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    VueRecaptcha,
    vSelect,
    BCard,
    BButton,
    BSpinner,
    BForm,
  },
  mixins: [alphanumeric],

  data() {
    return {
      pending: false,
      required,
      recaptchaToken: '',
      recaptcha: null,
      platforms: [
        { text: 'mt4', value: 'mt4_demo' },
      ],
      group: [],
      groupSelected: {},
      platformSelected: {},
      leverageSelected: '',
      full_name: '',
      mobile: '',
      email: '',
      country_id: '',
      leverage: [],
      formsListData: {
        pre_country_code: [],
      },
    }
  },
  computed: {

    sitekey() {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY
    },
  },
  watch: {
    platformSelected(val) {
      this.onchangePlatform(val)
    },
    groupSelected(val) {
      this.onchangeGroup(val)
    },
  },
  async mounted() {
    await this.getData()
    if (this.$route.params.platform) {
      // eslint-disable-next-line eqeqeq
      const platform = this.platforms.find(item => item.text == this.$route.params.platform)
      this.platformSelected = platform
    }
  },
  methods: {
    async getData() {
      await formData.allproperties().then(res => {
        const result = res.data.results.countries
        this.formsListData.pre_country_code = result.map(item => ({
          // eslint-disable-next-line global-require, import/no-unresolved, import/no-dynamic-require
          precode: item.precode, id: item.id, name: item.name, image: require(`@/assets/images/flags/${item.abbreviation}.png`),
        }))
      })
    },
    onchangePlatform(item) {
      if (!item) {
        this.groupSelected = ''
        this.leverageSelected = ''
        this.leverage = ''
        this.group = ''
        return
      }
      this.show = true
      demo.demoGroups({ platform: item.value }).then(res => {
        this.group = res.data.results
        if (this.$route.params.group) {
          // eslint-disable-next-line no-shadow, eqeqeq
          const group = this.group.find(item => item.name == this.$route.params.group)
          this.groupSelected = group
        }
      }).finally(() => {
        this.show = false
      })
    },
    onchangeGroup(group) {
      if (!group) {
        this.leverageSelected = ''
        this.leverage = ''
        return
      }

      this.leverage = this.group.filter(item => item.name === group.name)[0].leverages
    },
    createAcc() {
      this.pending = true
      const data = {
        platform: this.platformSelected.value,
        group_id: this.groupSelected.id,
        leverage_id: this.leverageSelected.id,
        full_name: this.full_name,
        email: this.email,
        mobile: this.mobile,
        country_id: this.country_id.id,
        recaptcha: this.recaptcha,
      }
      demo.createDemoAccount(data).then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 4000,
          showConfirmButton: false,
        })
        this.$refs.createDemoAccount.reset()
        this.country_id = ''
        this.platformSelected = ''
        this.groupSelected = ''
        this.leverageSelected = ''
      }).finally(() => {
        this.pending = false
      })
    },
    onVerify(response) {
      this.recaptcha = response
    },
    async onCaptchaExpired() {
      this.$refs.recaptcha.reset()
      this.recaptcha = null
    },
  },
}
</script>
